import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";
import {GoToPopup} from "../homepage"

const topImage = require("../../assets/images/homepage/header_img_home.png");

export const icon1 = require("../../assets/images/10-pages/usp_2/usp_2_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/usp_2/usp_2_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/usp_2/usp_2_sec_3_icon.png");
export const icon4 = require("../../assets/images/10-pages/usp_2/usp_2_sec_4_icon.png");

const section_one = require("../../assets/images/10-pages/usp_2/usp_2_sec_1.png");
const section_two = require("../../assets/images/10-pages/usp_2/affordable_sec_2.png");
const section_three = require("../../assets/img/chatbot/it_chatbot/it_chatbot_sec_4.png");

const featureContent = [
  {
    header: "Easy setup and scale. pay as you go",
    content:
      "Setup workativ assistant in less than a minute. No installation, no custom backend integration with apps required. Adding more employees, no problem – we do not charge by employees. Our plans are flat, and you can select monthly or annual subscription on different plans based on your usage.",
    image: section_one,
    alt: "affordable chatbot builder"
  },
  {
    header: "It’s two-in-one. Double whammy",
    content:
      "Workativ assistant comes with both conversational ai and workflow automation in single platform so you don’t have to buy separately, or build custom integrations etc. Saving you a ton.",
    image: section_two,
    alt: "chatbot with conversational ai and workflow automation"
  },
  {
    header: "Workativ Vs. Others",
    content:
      "Workativ assistant platform is designed with the goal to help you automate workplace support compared to other general-purpose chatbots in the market. All our R&D is focused on helping you deliver great employee service experience.",
    image: section_three,
    alt: "automate workplace support with workativ assistant"
  }
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Affordable Chatbot - Free signup, Free to use | Workativ Assistant Chatbot"
        description="Affordable chatbot solution for HR and IT support. No installation charges. Zero setup time. Pay as you go subscription model. An alternative to expensive chatbot solutions."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Affordable Chatbot - Free signup, Free to use | Workativ Assistant Chatbot"
        ogImage={topImage}
        ogDescription="Affordable chatbot solution for HR and IT support. No installation charges. Zero setup time. Pay as you go subscription model. An alternative to expensive chatbot solutions."
      />
      <Container>
        <Layout backgroundColor={"bg_usp_page_two"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_usp_page_two"}
            altImage={"Affordable Chatbot platform"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Finally, an affordable chatbot platform
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Say goodbye to expensive and bulky chatbot solutions, heavy
                backed app integrations and long project timelines.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              Because we believe innovation should not be a burden
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.alt}
                >
                  <RightImageWithContentFeature.Header>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.alt}
              >
                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                </LeftImageWithContent.SubHeader>
              </LeftImageWithContent>
            );
          })}
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
